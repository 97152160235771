import {SOCKET_URL} from "constants/common";
import {useEffect, useState} from "react";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {HitType} from "../../types/hit";
import {AuthDto} from "../../types/auth";
import {getAuthorization} from "../../utils/local-storage";

export enum WSMessageType {
    FightStarted = 'FIGHT_STARTED',
    RoundFinished = 'ROUND_FINISHED',
    FightFinished = 'FIGHT_FINISHED',
}

export interface WSMessageDto {
    type: WSMessageType;
}

export interface RoundFinishedStateDto extends WSMessageDto {
    damages: [{
        userId: string;
        type: "DAMAGE";
        hitType: HitType;
        targetId: string;
        damageValue: number;
    }];
}

export interface MatchStateDto {
    userSates: [UserMatchState];
    startedAt: number;
    match: {
        id: string;
        type: 'WINNER';
    };
    owner: UserMatchState;
    rules: {
        botAwaitingTimeInSeconds: number
    };
}

export interface FightStartedStateDto extends WSMessageDto {
    fight: {
        id: string;
        userStates: [UserState];
        type: 'FIGHT_STARTED'
    };
}

export interface FightFinishedStateDto extends WSMessageDto {
    winner?: {
        userId: string;
        type: 'WINNER';
    };
}

export interface UserMatchState {
    user: {
        id: string;
        score: number;
    };
    params: {
        maxHealth: number;
        maxDamage: number;
    };
}

export interface UserState {
    user: {
        id: string;
        score: number;
    };
    params: {
        maxHealth: number;
        maxDamage: number;
    };
    health: number;
    hitSelectedAt: number;
}

export interface Params {
    onFightStarted: (state: FightStartedStateDto) => void;
    onRoundFinished: (state: RoundFinishedStateDto) => void;
    onFightFinished: (state: FightFinishedStateDto) => void;
    onWSClose: () => void;
}

export const useFightWs = ({onWSClose, onFightStarted, onRoundFinished, onFightFinished}: Params) => {
    const [connect, setConnect] = useState(false);

    let authorization = getAuthorization() as AuthDto;

    const {lastJsonMessage, readyState, sendMessage} = useWebSocket(
        SOCKET_URL,
        {
            onOpen: () => {
                console.log("WS was opened ...");
            },
            onClose: () => {
                console.log("WS was closed ...");
                onWSClose();
            },
            onError: (err: Event) => {
                console.error(`WS error ...\n${JSON.stringify(err)}`);
                setConnect(false);
            },
            queryParams: {authorization: authorization?.apiKey}
        },
        connect
    );

    useEffect(() => {
        if (lastJsonMessage !== null) {
            const parsedMessage = lastJsonMessage as WSMessageDto;
            console.log('WS message: ', parsedMessage);

            if (parsedMessage.type) {
                switch (parsedMessage.type) {
                    case WSMessageType.FightStarted:
                        const fightStarted = parsedMessage as FightStartedStateDto;
                        onFightStarted(fightStarted);
                        break;
                    case WSMessageType.RoundFinished:
                        const roundFinished = parsedMessage as RoundFinishedStateDto;
                        onRoundFinished(roundFinished);
                        break;
                    case WSMessageType.FightFinished:
                        const fightFinished = parsedMessage as FightFinishedStateDto;
                        onFightFinished(fightFinished);
                        break;
                    default:
                        break;
                }
            }
        }
    }, [lastJsonMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return {
        connectionStatus,
        connectWS: () => {
            console.log("WS connecting ...");
            setConnect(true);
        },
        disconnectWS: () => {
            console.log("WS disconnecting ...");
            setConnect(false);
        },
        hit: (hitType: HitType) => sendMessage(JSON.stringify({
            type: 'HIT',
            hitType: hitType
        })),
        giveUp: () => sendMessage(JSON.stringify({
            type: 'GIVE_UP'
        }))
    };
};
