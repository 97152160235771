import React, {useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import './App.css';
import './components/MainPage.css';
import {FightFinishedStateDto} from 'api/hooks/useFightWs';
import {AuthDto} from 'types/auth';
import LoginPage from './components/LoginPage';
import MainPage from './components/MainPage';
import FightResultPage from './components/FightResultPage';
import {getAuthorization, removeAuthorization, saveAuthorization} from "./utils/local-storage";

function App() {
    const [fightFinishedState, setFightFinishedState] = useState<FightFinishedStateDto | undefined>(undefined);

    const [authState, setAuthState] = useState<AuthDto | undefined>(() => {
        return getAuthorization();
    });

    const handleLogin = (authDto: AuthDto) => {
        setAuthState(authDto);
        saveAuthorization(authDto);
    };

    const handleLogout = () => {
        setFightFinishedState(undefined);
        setAuthState(undefined);
        removeAuthorization();
    };

    let mainComponent = fightFinishedState && <Navigate to="/fight-result" replace/>;
    mainComponent = authState ? mainComponent : <Navigate to="/login" replace/>;
    mainComponent = mainComponent ? mainComponent : <MainPage
        authDto={authState!}
        onFightFinished={(finishedStateDto: FightFinishedStateDto) => {
            setFightFinishedState(finishedStateDto)
        }}
        onLogout={handleLogout}
    />

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/">
                        <Route index element={mainComponent}/>
                        <Route path="login" element={
                            authState ? <Navigate to="/" replace/>
                                : <LoginPage onLogin={handleLogin}/>
                        }/>
                        <Route path="fight-result" element={
                            !fightFinishedState ? <Navigate to="/" replace/> :
                                <FightResultPage
                                    userId={fightFinishedState.winner ? fightFinishedState?.winner.userId : undefined}
                                    onBackToMainPage={() => {
                                        setFightFinishedState(undefined);
                                    }}
                                />
                        }/>
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                    </Route>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
