import React, {useEffect, useState} from 'react';
import {AuthDto} from 'types/auth';
import {HitType} from "../types/hit";
import Pentagram from "./Pentagram";
import FightHistory from "./FightHistory";
import {
    FightFinishedStateDto,
    FightStartedStateDto,
    MatchStateDto,
    RoundFinishedStateDto,
    useFightWs
} from 'api/hooks/useFightWs';
import {useMatch} from "../api/hooks/useMatch";
import {useNavigate} from "react-router-dom";

interface MainPageProps {
    authDto: AuthDto,
    onFightFinished: (state: FightFinishedStateDto) => void,
    onLogout: () => void,
}

const MainPage: React.FC<MainPageProps> = ({
                                               onLogout,
                                               onFightFinished,
                                               authDto
                                           }) => {
    const [userHealth, setUserHealth] = useState<number>(0);
    const [opponentHealth, setOpponentHealth] = useState<number>(0);
    const [matchCreatedState, setMatchCreatedState] = useState<MatchStateDto | undefined>(undefined);
    const [fightStartedState, setFightStartedState] = useState<FightStartedStateDto | undefined>(undefined);
    const [roundFinishedState, setRoundFinishedState] = useState<RoundFinishedStateDto | undefined>(undefined);
    const [awaitingOpponent, setAwaitingOpponent] = useState(true);
    const navigate = useNavigate();

    const {
        requestMatch
    } = useMatch();

    const {
        connectionStatus,
        connectWS,
        disconnectWS,
        hit,
        giveUp
    } = useFightWs({
        onWSClose: () => {
            navigate('/');
        },
        onFightStarted: (state) => {
            setFightStartedState(state);
        },
        onRoundFinished: (state) => {
            setRoundFinishedState(state);
        },
        onFightFinished: (state) => {
            onFightFinished(state);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const matchStateDto: MatchStateDto = await requestMatch(authDto.user.id, 50, 10);
            setMatchCreatedState(matchStateDto);
            console.log('Match request ', matchStateDto)
        }
        fetchData().catch(console.error);
        return () => {
            setMatchCreatedState(undefined);
        }
    }, []);

    useEffect(() => {
        if (matchCreatedState) {
            connectWS();
            return () => {
                disconnectWS();
            }
        }
    }, [matchCreatedState]);

    useEffect(() => {
        if (fightStartedState) {
            const userState = fightStartedState.fight.userStates.find(s => s.user.id === authDto.user.id);
            const opponentState = fightStartedState.fight.userStates.find(s => s.user.id !== authDto.user.id);
            setUserHealth(userState?.health ?? 0);
            setOpponentHealth(opponentState?.health ?? 0);
            setAwaitingOpponent(false);
        }
    }, [fightStartedState]);

    useEffect(() => {
        if (roundFinishedState) {
            const userDamage = roundFinishedState.damages.find(damage => damage.targetId === authDto.user.id);
            const opponentDamage = roundFinishedState.damages.find(damage => damage.targetId !== authDto.user.id);

            if (userDamage) {
                setUserHealth(prev => Math.max(prev - userDamage.damageValue, 0));
            }
            if (opponentDamage) {
                setOpponentHealth(prev => Math.max(prev - opponentDamage.damageValue, 0));
            }
            setAwaitingOpponent(false)
        }
    }, [roundFinishedState]);

    const handleLogoutButton = () => {
        setAwaitingOpponent(true);
        giveUp();
        disconnectWS();
        onLogout();
    }

    const onRequestHit = (hitType: HitType) => {
        setAwaitingOpponent(true);
        hit(hitType);
    }

    return (
        <div className='main-page'>
            <div className='user-info-and-health-container'>
                <div className='user-info'>
                    <div id="connection-lamp" className="connection-lamp" style={
                        {backgroundColor: connectionStatus.startsWith("Open") ? "green" : "red"}
                    }></div>
                    <div style={
                        {color: connectionStatus.startsWith("Open") ? "white" : "red"}
                    }>
                        {connectionStatus.startsWith("Open") ? "You in the hell!" : "Notify your master. Something happens in the hell."}
                    </div>
                    <div>Slave's monicker: {authDto.user.id}</div>
                    <div>Soul power: {matchCreatedState ? matchCreatedState.owner.user.score : authDto.user.score}</div>
                    <button className="logout-button" onClick={handleLogoutButton}>Suicide</button>
                </div>
                <div className='health-bar'>
                    <div className='user-health' style={{width: `${userHealth}%`}}></div>
                    <div className='opponent-health' style={{width: `${opponentHealth}%`}}></div>
                </div>
            </div>
            <Pentagram disabled={awaitingOpponent} sendHit={onRequestHit}/>
            <FightHistory roundFinishedState={roundFinishedState}/>
        </div>
    );
};

export default MainPage;
