import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import './Loader.css';

interface LoaderProps {
    text?: string;
}

const Loader: React.FC<LoaderProps> = ({text}) => {
    return (
        <div className="loader-container">
            <FontAwesomeIcon icon={faSpinner} spin size="2x" className="loader-spinner"/>
            {text && <p className="loader-text">{text}</p>}
        </div>
    );
};

export default Loader;