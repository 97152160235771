import React, {useEffect, useState, useRef} from 'react';
import {RoundFinishedStateDto} from "../api/hooks/useFightWs";
import './FightHistory.css';

interface FightHistoryProps {
    roundFinishedState?: RoundFinishedStateDto;
}

const FightHistory: React.FC<FightHistoryProps> = ({roundFinishedState}) => {
    const [fightHistory, setFightHistory] = useState<Array<RoundFinishedStateDto>>([]);
    const fightHistoryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setFightHistory(prevState => {
            if (!roundFinishedState) {
                return prevState;
            }
            return [...prevState, roundFinishedState];
        });
    }, [roundFinishedState]);

    useEffect(() => {
        if (fightHistoryRef.current) {
            fightHistoryRef.current.scrollTop = fightHistoryRef.current.scrollHeight;
        }
    }, [fightHistory]);

    return (
        <div className="fight-history" ref={fightHistoryRef}>
            <h2 style={{marginTop: '0px'}}>Fight Story</h2>
            <ul>
                {fightHistory.map((state, index1) => (
                    state.damages.map((damage, index2) => (
                        <li key={`${index1}-${index2}`}>
                            <div>The user
                                 <span style={{color: 'white'}}> {damage.userId}</span> deal
                                 <span style={{color: 'darkred'}}> {damage.hitType}</span> hit with
                                 <span style={{color: 'darkred'}}> {damage.damageValue}</span> damage to user
                                 <span style={{color: 'white'}}> {damage.targetId}</span>
                            </div>
                        </li>
                    ))
                ))}
            </ul>
        </div>
    );
};

export default FightHistory;
