import {HitType} from "../types/hit";
import React from "react";
import baphometStar from "../image/baphomet-star.svg";
import {faHandLizard, faHandPaper, faHandRock, faHandScissors, faHandSpock,} from '@fortawesome/free-solid-svg-icons';
import "./Pentagram.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Loader from "./Loader";

interface PentagramProps {
    sendHit: (hitType: HitType) => void;
    disabled: boolean;
}

const Pentagram: React.FC<PentagramProps> = ({sendHit, disabled}) => {
    return (
        <div className="pentagram-container">
            <div className="hit-button-container">
                <img src={baphometStar} alt="Pentagram" className="pentagram-img"/>
                <button className="hit-button rock" onClick={() => sendHit(HitType.ROCK)} disabled={disabled}>
                    <FontAwesomeIcon className="hit-button-icon" icon={faHandRock}/>
                </button>
                <button className="hit-button scissors" onClick={() => sendHit(HitType.SCISSORS)} disabled={disabled}>
                    <FontAwesomeIcon className="hit-button-icon" icon={faHandScissors}/>
                </button>
                <button className="hit-button paper" onClick={() => sendHit(HitType.PAPER)} disabled={disabled}>
                    <FontAwesomeIcon className="hit-button-icon" icon={faHandPaper}/>
                </button>
                <button className="hit-button lizard" onClick={() => sendHit(HitType.LIZARD)} disabled={disabled}>
                    <FontAwesomeIcon className="hit-button-icon" icon={faHandLizard}/>
                </button>
                <button className="hit-button spock" onClick={() => sendHit(HitType.SPOCK)} disabled={disabled}>
                    <FontAwesomeIcon className="hit-button-icon" icon={faHandSpock}/>
                </button>
            </div>
            {disabled && <Loader text="Waiting for the opponent..."/>}
        </div>
    );
};

export default Pentagram;