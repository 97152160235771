const AUTHORIZATION = "Authorization"

export const saveAuthorization = (authData: any) => {
    let authorization = JSON.stringify(authData);
    localStorage.setItem(AUTHORIZATION, authorization);
}
export const removeAuthorization = () => localStorage.removeItem(AUTHORIZATION);
export const getAuthorization = () => {
    let authorization = localStorage.getItem(AUTHORIZATION);
    return authorization ? JSON.parse(authorization) : undefined;
}
