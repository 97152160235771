import {apiAuth} from "../endpoints";

export const useAuth = () => {
    const requestAuthorization = async (userId: string) => {
        try {
            const response = await apiAuth(userId);
            const {data, error} = response;

            if (error) {
                throw new Error(error);
            }
            return data;
        } catch (error) {
            throw error;
        }
    }

    return {requestAuthorization};
};