import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {BASE_URL} from 'constants/common';
import {AuthDto} from "../types/auth";
import {getAuthorization} from "../utils/local-storage";
// import { getTokenLS } from 'utils/localStorage';

const onSuccessRequestCallback = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    if (!newConfig.headers) {
        // @ts-ignore
        newConfig.headers = {};
    }
    let authorization = getAuthorization() as AuthDto;

    newConfig.headers.Authorization = authorization?.apiKey;

    return newConfig;
};


export const onSuccessResponseCallback = (response: AxiosResponse) => response;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onErrorResponseCallback = (error: any) => {
    if (
        error.config.url !== '/api/auth/login' &&
        error.response?.status === 401 &&
        error.response?.statusText === 'Unauthorized'
    ) {
        localStorage.clear();
    }
    return Promise.reject(error);
};

export const apiClient = axios.create({
    baseURL: BASE_URL
});

apiClient.interceptors.request.use(onSuccessRequestCallback);

apiClient.interceptors.response.use(onSuccessResponseCallback, onErrorResponseCallback);
