import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Loader from './Loader';
import './LoginPage.css';
import {AuthDto} from "../types/auth";
import {useAuth} from "../api/hooks/useAuth";

interface Props {
    onLogin: (authDto: AuthDto) => void
}

const LoginPage = ({onLogin}: Props) => {
    const navigate = useNavigate();
    const {requestAuthorization} = useAuth();

    const [authRequested, setAuthRequesting] = useState(false);

    const handleAuthorizationResponse = (authDto: AuthDto) => {
            onLogin(authDto);
            navigate('/');
    }

    const handleLoginButton = async (userId: string) => {
        setAuthRequesting(true)
        let authDto: AuthDto = await requestAuthorization(userId);
        handleAuthorizationResponse(authDto)
        setAuthRequesting(false)
    };

    return (
        <div className="login-page">
            {!authRequested && <div className="login">
                <h1>Welcome</h1>
                <button
                    className="connect-button"
                    onClick={() => (async (userId: string) => {
                        await handleLoginButton(userId);
                    })('1')}
                >
                    Login 1
                </button>
                <span>  </span>
                <button
                    className="connect-button"
                    onClick={() => (async (userId: string) => {
                        await handleLoginButton(userId);
                    })('2')}
                >
                    Login 2
                </button>
            </div>}
            {authRequested && <Loader text="Connecting..."/>}
        </div>
    );
};

export default LoginPage;