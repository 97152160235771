import React from 'react';
import './FightResultPage.css';

interface FightResultPageProps {
    userId?: string;
    onBackToMainPage(): void;
}

const FightResultPage: React.FC<FightResultPageProps> = ({userId, onBackToMainPage}) => {
    return (
        <div className="fight-result-page">
            <h1>{userId ? userId + ' survived. The others didn\'t.' : 'Everyone gonna die' }</h1>
            <button onClick={() => onBackToMainPage()}>Play Again</button>
        </div>
    );
};

export default FightResultPage;