import {apiMatch} from "../endpoints";
import {MatchStateDto} from "./useFightWs";

export const useMatch = () => {
    const requestMatch = async (userId: string, maxHealth: Number, maxDamage: Number): Promise<MatchStateDto> => {
        try {
            const response = await apiMatch(userId, maxHealth, maxDamage);
            const {data, error} = response;

            if (error) {
                throw new Error(error);
            }
            return data;
        } catch (error) {
            throw error;
        }
    }

    return {requestMatch};
};