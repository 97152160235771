import {ResponseDto} from 'types/common';
import {apiClient} from './apiClient';
import {withDefaultHandlers} from './helpers';
import {AuthDto} from 'types/auth';
import {MatchStateDto} from "./hooks/useFightWs";

// this API should be called by the main backend side in the future
// replace by requesting main backend for match creation
export const apiAuth = (userId: string) =>
    withDefaultHandlers<ResponseDto<AuthDto>>(apiClient.post(`/auth`, undefined, {params: {userId}}));

// this API should be called by the main backend side in the future
// replace by requesting main backend for match creation
export const apiMatch = (userId: string, maxHealth: Number, maxDamage: Number) =>
    withDefaultHandlers<ResponseDto<MatchStateDto>>(apiClient.post(`/api/match`, undefined, {
        params: {
            userId,
            maxHealth,
            maxDamage
        }
    }));
